import React, { Component } from "react";
import Layout from "../layout/Layout";
import styled from "styled-components";
import posed from 'react-pose';
import NCarousel from 'nuka-carousel';
import { Link } from 'react-scroll';
import { lightConsoles, soundConsoles } from '../data/consoles';
import LazyLoad from 'react-lazy-load';

const Description = styled.div`
  padding: 2rem;
  margin-bottom: 2rem;
`;

const Heading = styled.h2`
    margin: 0 0 12px 0;
    padding: 0;
    font-weight: ${({theme}) => theme.font.bold};
    font-size: 2rem;
    color: ${({theme}) => theme.colors.secondary};
    position: relative;

    ${({theme}) => theme.media.desktop} {
        ::before {
            background: url('nav-indicator-${({variant}) => variant}.svg') no-repeat 0 0;
            content: '';
            position: absolute;
            top: -18px;
            left: -80px;
            width: 56px;
            height: 72px;
        }
    }
`;

const SecondaryHeading = styled.h3`
    margin: 2rem 0 12px 0;
    padding: 0;
    font-weight: ${({theme}) => theme.font.bold};
    font-size: 1.7rem;
    color: ${({theme}) => theme.colors.secondary};
`;

const List = styled.ul`
    padding-left: 0;
`;

const ListItem = styled.li`
    font-size: 1.2rem;
    line-height: 1.9rem;
    list-style-type: none;
    padding-left: 2rem;
    position: relative;

    margin-bottom: 0.5rem;

    &:before {
        content: '';
        background: url('nav-indicator-${({variant}) => variant}.svg') no-repeat 0 0;
        display: block;
        height: 20px;
        width: 16px;
        position: absolute;
        left: 0;
        top: 2px;
    }
`;

const Excerpt = styled.p`
  margin: 0;
  font-size: 1.2rem;
  line-height: 1.9rem;
`;

const Logo = styled.img`
    max-width: 463px;
    width: 80%;
    margin-top: 7rem;

    ${({theme}) => theme.media.desktop} {
        margin-top: 10rem;
    }
`;

const SectionBelowLogo = styled.div`
    max-width: 1440px;
    width: 100%;
    position: relative;
`;

const ConsoleWrapper = styled.div`
    max-width: 848px;
    width: 100%;
    min-height: 300px;
    padding-top: 2rem;
    margin-bottom: 3rem;
`;

const Claim = styled.h1`
    font-weight: ${({theme}) => theme.font.medium};
    font-size: 1.5rem;
    text-align: center;
    margin: 2rem 1.5rem 7rem;

    ${({theme}) => theme.media.desktop} {
        margin-bottom: 10rem;
    }
`;

const Items = styled.div`
    height: 350px;
    background-size: cover;
    display: block;
    width: 100%;
    padding: 2rem 1rem;
    vertical-align: top;

    ${({theme}) => theme.media.tablet} {
        height: 494px;
    }

    ${({theme}) => theme.media.desktop} {
        display: inline-block;
        width: 50%;
        padding: 2.5rem 2rem;
    }
`;

const ItemsHeading = styled.h2`
    font-family: prohibition, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5rem;
    color: ${({theme}) => theme.colors.white};
    text-align: center;
    margin: 0;
    padding: 0;
`;

const ItemsLinksWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const PosedItemLink = posed.div({
    hoverable: true,
    pressable: true,
    init: {
        scale: 1,
    },
    hover: {
        scale: 1.05,
    },
    press: {
        scale: 0.95,
    },
});

const ItemLink = styled(PosedItemLink)`
    display: inline-flex;
    width: 105px;
    height: 132px;
    background-size: cover;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin: 5px;
    margin-top: 50px;

    color: ${({theme}) => theme.colors.secondary};
    font-weight: ${({theme}) => theme.font.medium};
    font-size: 1rem;
    cursor: pointer;

    small {
        font-size: 0.8rem;
    }

    ${({theme}) => theme.media.tablet} {
        width: 145px;
        height: 183px;
        margin-top: 100px;
        font-size: 1.5rem;

        small {
            font-size: 0.9rem;
        }
    }
`;

const ItemLightLink = styled(ItemLink)`
    background: url('nav-indicator-blue2.svg') no-repeat center center;
`;

const ItemSoundLink = styled(ItemLink)`
    background: url('nav-indicator-orange2.svg') no-repeat center center;
`;

const ItemsLight = styled(Items)`
    background: url('items-light.jpg') no-repeat center center;
`;

const ItemsSound = styled(Items)`
    background: url('items-sound.jpg') no-repeat center center;
`;

const ItemsContact = styled(Items)`
    text-align: center;
    height: auto !important;
    padding-top: 0 !important;
`;

const SectionHeading = styled.h2`
    font-family: prohibition, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.4rem;
    color: ${({theme}) => theme.colors.black};
    text-align: center;
    margin: 8rem 1rem 5rem;
    padding: 0;
`;

const PosedArrow = posed.a({
    hoverable: true,
    pressable: true,
    init: {
        scale: 0.9,
        opacity: 0.2,
    },
    hover: {
        scale: 1,
        opacity: 0.8,
    },
    press: {
        scale: 0.9,
    },
});

const Arrow = styled(PosedArrow)`
    width: 25px;
    height: 104px;
    background-size: cover;
    display: block;
    cursor: pointer;
`;

const ArrowLeft = styled(Arrow)`
    background: url('arrow-left.svg') no-repeat center center;
    margin-left: 1rem;
`;

const ArrowRight = styled(Arrow)`
    background: url('arrow-right.svg') no-repeat center center;
    margin-right: 1rem;
`;

const Footer = styled.footer`
    background: ${({theme}) => theme.colors.gray};
    width: 100%;
    max-width: 1440px;
`;

const ContactInfo = styled.p`
    font-size: 1.3rem;
    margin: 0.5rem 1rem;
`;

const Copyright = styled.div`
    text-align: center;
    font-size: 0.9rem;
    margin: 2rem 1rem 1rem;
`;

const ImageContainer = styled.div`
    width: 100%;
    padding-bottom: 80%;
    position: relative;
    background: ${({theme}) => theme.colors.gray};
    
    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
`;

const Console = props => (
    <ConsoleWrapper name={props.item.name}>
        <Description>
            <Heading variant={props.variant}>{props.item.heading}</Heading>
            <Excerpt>{props.item.excerpt}</Excerpt>
        </Description>
        <NCarousel 
            renderBottomCenterControls={null}
            renderCenterLeftControls={({ previousSlide }) => (
                <ArrowLeft onClick={previousSlide} />
            )}
            renderCenterRightControls={({ nextSlide }) => (
                <ArrowRight onClick={nextSlide} />
            )}
            wrapAround
        >
            <img src={props.item.mainImage} alt={props.item.heading} />
            {props.item.images.map((image, index) => (
                <LazyLoad
                    key={`${props.item.heading}${index}`}
                    debounce={false}
                    offsetVertical={500}
                >
                    <ImageContainer>
                        <img src={image} alt={props.item.heading} />
                    </ImageContainer>
                </LazyLoad>
            ))}
        </NCarousel>
        <Description>
            <SecondaryHeading>
                Specs:
            </SecondaryHeading>
            <List>
                {props.item.specs.map((spec, index) => (
                    <ListItem key={index} variant={props.variant}>{spec}</ListItem>
                ))}
            </List>
        </Description>
    </ConsoleWrapper>
)

class MainPage extends Component {
    render() {
        return (
            <Layout name="section-home">
                <Logo src="cs-logo.svg" alt="Console Solutions" />
                <Claim>
                    With passion to deliver consoles for your perfect job!
                </Claim>
                <SectionBelowLogo>
                    <ItemsLight>
                        <ItemsHeading>
                            Solutions for Light
                        </ItemsHeading>
                        <ItemsLinksWrapper>
                            <Link 
                                to="ma3"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemLightLink>
                                    MA3
                                </ItemLightLink>
                            </Link>
                            <Link 
                                to="ch"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemLightLink>
                                    ChamSys
                                </ItemLightLink>
                            </Link>
                            {/* <Link 
                                to="ma2fw"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemLightLink>
                                    MA2 <small>Command</small><small>&amp; Fader</small>
                                </ItemLightLink>
                            </Link> */}
                            <Link 
                                to="vistaex"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemLightLink>
                                    Vista EX
                                </ItemLightLink>
                            </Link>
                        </ItemsLinksWrapper>
                    </ItemsLight>
                    <ItemsSound>
                        <ItemsHeading>
                            Solutions for Sound
                        </ItemsHeading>
                        <ItemsLinksWrapper>
                            <Link 
                                to="dlive"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemSoundLink>
                                    dLive
                                </ItemSoundLink>
                            </Link>
                            <Link 
                                to="waveslv1l"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemSoundLink>
                                    Waves<span>LV1</span>
                                </ItemSoundLink>
                            </Link>
                            <Link 
                                to="waveslv1l"
                                spy
                                smooth
                                duration={500}
                            >
                                <ItemSoundLink>
                                    Flyable<small>lightweight</small>
                                </ItemSoundLink>
                            </Link>
                        </ItemsLinksWrapper>
                    </ItemsSound>
                </SectionBelowLogo>
                <SectionHeading name="section-light">Solutions for Light</SectionHeading>
                {lightConsoles.map((console, index) => (
                    <Console variant="blue" key={index} item={console} />
                ))}
                <SectionHeading name="section-sound">Solutions for Sound</SectionHeading>
                {soundConsoles.map((console, index) => (
                    <Console variant="orange" key={index} item={console} />
                ))}
                <Footer name="section-contact">
                    <SectionHeading>Let's get in touch</SectionHeading>
                    <ItemsContact>
                        <SecondaryHeading>Marek Ratuszny</SecondaryHeading>
                        <ContactInfo>+48 608 029 164</ContactInfo>
                        <ContactInfo>light@consolesolutions.pl</ContactInfo>
                    </ItemsContact>
                    <ItemsContact>
                        <SecondaryHeading>Jacek Dyrda</SecondaryHeading>
                        <ContactInfo>+48 601 639 909</ContactInfo>
                        <ContactInfo>sound@consolesolutions.pl</ContactInfo>
                    </ItemsContact>
                    <Copyright>
                        &copy; 2023 Console Solutions | Website brought to you by <a href="https://doh.how/" target="_blank" rel="noopener noreferrer">DOH!</a> and <a href="https://moonite.co" target="_blank" rel="noopener noreferrer">moonite</a>
                    </Copyright>
                </Footer>
            </Layout>
        )
    }
}

export default MainPage;