import {colors} from './colors';

export const theme = {
    colors,
    font: {
        light: 300,
        book: 400,
        medium: 500,
        bold: 700,
    },
    media: {
        tablet: '@media (min-width: 768px)',
        desktop: '@media (min-width: 1024px)',
    },
}