import React, { Component } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import {theme} from "../utils/theme";
import { Link, animateScroll as scroll } from 'react-scroll';
import posed from 'react-pose';
import { Helmet } from 'react-helmet';

const GlobalStyle = createGlobalStyle`
    @import url("https://use.typekit.net/xgk8tul.css"); 

    body {
        padding: 0;
        margin: 0;

        font-family: factoria, serif;
        font-weight: ${({theme}) => theme.font.book};
        font-style: normal;

        font-size: 16px;

        color: ${({theme}) => theme.colors.black};
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
        color: ${({theme}) => theme.colors.secondary};
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const Navigation = styled.div`
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    position: fixed;
    z-index: 10;
    top: 0px;

    padding-right: 32px;

    ${({theme}) => theme.media.tablet} {
        padding-right: 50px;
    }

    ${({theme}) => theme.media.desktop} {
        padding-right: 80px;
    }
`;

const Hamburger = styled.div`
    background: url('navigation-marker.svg') no-repeat 0 0;
    width: 60px;
    height: 60px;
    background-size: contain;
    position: relative;
    cursor: pointer;

    ::before {
        position: absolute;
        top: 15px;
        left: 20px;

        width: 20px;
        height: 20px;
    
        background: url('${({showClose}) => showClose ? 'close-icon' : 'hamburger'}.svg') no-repeat 0 0;
        background-size: contain;
        transition: background-image 0.2s ease-in-out;

        content: '';      
        ${({showClose}) => showClose ? 'z-index: 30' : ''}  
    }

    ${({theme}) => theme.media.tablet} {
        width: 90px;
        height: 90px;

        ::before {
            top: 25px;
            left: 30px;

            width: 30px;
            height: 30px;
        }
    }  

    ${({theme}) => theme.media.desktop} {
        width: 122px;
        height: 122px;

        ::before {
            top: 35px;
            left: 41px;

            width: 40px;
            height: 40px;
        }
    }    
`;

const PosedNavigationItems = posed.div({
    visible: {
        y: '0%',
        staggerChildren: 100,
    },
    hidden: {
        y: '-100%',
    }
});

const PosedNavigationItem = posed.span({
    hoverable: true,
    pressable: true,
    press: {
        scale: 0.9,
    },
    init: {
        color: '#fff',
    },
    hover: {
        color: '#ee7068',
    },
    visible: {
        opacity: 1,
    },
    hidden: {
        opacity: 0,
    }
});

const NavigationItems = styled(PosedNavigationItems)`
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    background: ${({theme}) => theme.colors.secondary};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    overflow-y: scroll;
`;

const NavigationItem = styled(PosedNavigationItem)`
    font-family: prohibition, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2rem;
    color: ${({theme}) => theme.colors.white};
    text-decoration: none;
    margin-bottom: 0.6rem;
    margin-top: 0.9rem;
    display: block;
    cursor: pointer;

    ${({theme}) => theme.media.tablet} {
        font-size: 2.8rem;
        margin-bottom: 0.9rem;
        margin-top: 1.1rem;
    }
`;

const NavigationSubItem = styled(NavigationItem)`
    margin-top: 0;
    font-family: factoria, serif;
    font-weight: ${({theme}) => theme.font.light};
    font-size: 1.2rem;
    margin-top: 0.8rem;

    ${({theme}) => theme.media.tablet} {
        font-size: 1.5rem;
        margin-top: 1rem;
    }
`;

const PosedLoader = posed.div({
    visible: {
        y: '0%',
    },
    hidden: {
        y: '200%',
    }
});

const Loader = styled(PosedLoader)`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: ${({theme}) => theme.colors.secondary};
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        padding: 2rem;
        max-width: 500px;
    }
`;

class Layout extends Component {
    state = {
        navVisible: false,
        siteLoaded: false,
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.setState({ siteLoaded: true });
            window.dispatchEvent(new Event("resize"));
        }, 400);
    }

    toggleNavigation = () => {
        this.setState(prevState => ({ navVisible: !prevState.navVisible }));
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    render() {
        const { children } = this.props;
        const { navVisible, siteLoaded } = this.state;
        
        return(
            <ThemeProvider theme={theme}>
                <>
                    <Helmet>
                        <title>Console Solutions</title>
                        <meta name="description" content="With passion to deliver consoles for your perfect job! Explore our solutions for light and sound. Get in touch to know more about what we can deliver for you." />
                    </Helmet>
                    <GlobalStyle/>
                    <Loader pose={siteLoaded ? 'hidden' : 'visible'}>
                        <img src="cs-logo-white.svg" alt="Console Solutions" />
                    </Loader>
                    <Wrapper>
                        <Navigation>
                            <Hamburger showClose={navVisible} onClick={this.toggleNavigation} />
                            <NavigationItems
                                pose={navVisible ? 'visible' : 'hidden'}
                            >
                                <NavigationItem onClick={() => {
                                        this.scrollToTop();
                                        this.toggleNavigation();
                                    }}
                                >
                                    Home
                                </NavigationItem>
                                <Link to="section-light" spy smooth duration={500}>
                                    <NavigationItem onClick={this.toggleNavigation}>Light Solutions</NavigationItem>
                                </Link>
                                <Link to="ma3" spy smooth duration={500}>
                                    <NavigationSubItem onClick={this.toggleNavigation}>MA3 Command</NavigationSubItem>
                                </Link>
                                <Link to="ch" spy smooth duration={500}>
                                    <NavigationSubItem onClick={this.toggleNavigation}>ChamSys MagicQ Stadium Connect</NavigationSubItem>
                                </Link>
                                <Link to="ma2fw" spy smooth duration={500}>
                                    <NavigationSubItem onClick={this.toggleNavigation}>MA2 Command &amp; Fader</NavigationSubItem>
                                </Link>
                                <Link to="vistaex" spy smooth duration={500}>    
                                    <NavigationSubItem onClick={this.toggleNavigation}>Vista EX</NavigationSubItem>
                                </Link>
                                <Link to="section-sound" spy smooth duration={500}>
                                    <NavigationItem onClick={this.toggleNavigation}>Sound Solutions</NavigationItem>
                                </Link>
                                <Link to="dlive" spy smooth duration={500}>
                                    <NavigationSubItem onClick={this.toggleNavigation}>dLive</NavigationSubItem>
                                </Link>
                                <Link to="waveslv1" spy smooth duration={500}>
                                    <NavigationSubItem onClick={this.toggleNavigation}>Waves LV1</NavigationSubItem>
                                </Link>
                                <Link to="waveslv1l" spy smooth duration={500}>
                                    <NavigationSubItem onClick={this.toggleNavigation}>Waves LV1 Lightweight</NavigationSubItem>
                                </Link>
                                <Link to="section-contact" spy smooth duration={500}>
                                    <NavigationItem onClick={this.toggleNavigation}>Contact</NavigationItem>
                                </Link>
                            </NavigationItems>
                        </Navigation>
                        {children}
                    </Wrapper>
                </>
            </ThemeProvider>
        )
    }
}

export default Layout;