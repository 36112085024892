export const lightConsoles = [
    {
        name: "ma3",
        heading: "MA3 onPC Command Wing",
        excerpt: "High Quality Road Case c/w wheel-board, drawer with keyboard and a place to hold MA 2port node. Screens fold flat to allow a case of only 86cm x 82cm x 31cm. This system is ready to go and only requires an MA3 command wing, MA 2port node. All connections for the wing, USB, Mains and DMX are internal to hook the wing and 2port node to the system.",
        specs: [
            'External dimensions: 86cm x 82cm x 31cm (with wheelboard) 76cm x 82cm x 31cm (without wheelboard).',
            'Case: a place for MA 2 port node, drawer for keyboard, storage place for documents, tablet or third touch screen, storage place for cables, adjustable screen angle.',
            'PC: AMD Ryzen 9 5900HX, AMD RadeonTM Graphics 2100 MHz, 256GB SSD M.2, 32GB RAM, Windows 10 PRO.',
            'Screens: 2 x 15,6” touch screen (Full HD), 10 point multitouch',
            'UPS 600VA.',
            'Ethernet switch (8 x 10/100/1000Mbps).',
            'Wireless lit keyboard with touchpad.',
            'LED lighting for the wing.',
            '2 x gooseneck lamps.',
            'Neutrik connectors mount on the back panel: DMX IN, OUT (XLR 3 or 5pin), Power (TRUE1), Ethernet (RJ45).',
            'additional USB connector in the front (for pendrive with show).',
            'Dust cover.',
            'Cable for charging phone in the storage place (USB-C, microUSB, Apple Lighting).',
            'Weight of the set without Command Wing and 2Port Node: 52kg.',
        ],
        mainImage: "images/ma3/ma3cw_01@850w.jpg",
        images: [
            "images/ma3/ma3cw_02@850w.jpg",
            "images/ma3/ma3cw_03@850w.jpg",
            "images/ma3/ma3cw_04@850w.jpg",
            "images/ma3/ma3cw_05@850w.jpg",
            "images/ma3/ma3cw_06@850w.jpg",
            "images/ma3/ma3cw_07@850w.jpg",
            "images/ma3/ma3cw_08@850w.jpg",
            "images/ma3/ma3cw_09@850w.jpg",
        ],
    },
    {
        name: "ch",
        heading: "Chamsys MagicQ Stadium Connect",
        excerpt: "High Quality Road Case c/w wheel-board and 1 drawer. Screen fold flat to allow a case of only 76cm x 31cm x 78cm. This system is ready to go and only requires the Stadium Connect, all connections for the wing, USB, Mains and DMX are internal to hook the wing to the system.",
        specs: [
            'External dimensions: 76cm x 31cm x 78cm (with wheelboard) 76cm x 31cm x 67cm (without wheelboard).',
            'Case: drawer for keyboard, storage place for cables, adjustable screen angle.',
            'PC: AMD Ryzen 9 5900HX, AMD RadeonTM Graphics 2100 MHz, 256GB SSD M.2, 32GB RAM, Windows 10 PRO.',
            'Screens: 1 x 24” touch screen (Full HD), 10 point multitouch',
            'UPS 600VA.',
            'Ethernet switch (5 x 10/100/1000Mbps).',
            'Wireless lit keyboard with touchpad.',
            'LED lighting for the drawer.',
            '2 x socket for gooseneck lamps.',
            'Neutrik connectors mount on the back panel: 4 x DMX OUT (XLR 3 or 5pin), Power (TRUE1), 4 x Ethernet(RJ45), USB, HDMI.',
            'additional USB connector in the front (for pendrive with show)', 
            'Dust cover.',
            'Cable for charging phone in the storage place (USB-C, microUSB, Apple Lighting).',
            'Wireless phone charger',
            'Weight of the set with Stadium Connect: 53kg.',
        ],
        mainImage: "images/ch/ch_01@850w.jpg",
        images: [
            "images/ch/ch_02@850w.jpg",
            "images/ch/ch_03@850w.jpg",
            "images/ch/ch_04@850w.jpg",
            "images/ch/ch_05@850w.jpg",
            "images/ch/ch_06@850w.jpg",
            "images/ch/ch_07@850w.jpg",
            "images/ch/ch_08@850w.jpg",
            "images/ch/ch_09@850w.jpg",
            "images/ch/ch_10@850w.jpg",
            "images/ch/ch_11@850w.jpg",
        ],
    },
    {
        name: "ma2fw",
        heading: "MA2 onPC Command & Fader Wings",
        excerpt: "High Quality Road Case c/w wheel-board, cable compartment and 2 drawers. Screens fold flat to allow a case of only 100cm x 80cm x 30cm. This system is ready to go and only requires an MA command and fader wing, all connections for the wings, USB, Mains and DMX are internal to hook the wings to the system.",
        specs: [
            'External dimensions: 100cm x 80cm x 30cm.',
            'PC: Intel NUC i7 with 120GB SSD + 8GB RAM with Windows 10.',
            'SCREENS: 2 x 19" Resistive Touchscreens. The screens fold flat for transport and have 3 different viewing angles.',
            'UPS system.',
            'USB Hub.',
            'LED lighting of wings and and drawers c/w dimmer.',
            '2 x Adam Hall SLED1.',
            'Bluetooth keyboard c/w mouse pad.',
            'BACK PANEL: Connections for Powercon mains inlet, DMX in and out, 2 x Ethernet, RJ45 and 1 x USB. Back panel is protected for transport.',
            'USB in the front panel for show back up.', 
            'This system is ready to go and only requires an MA command and fader wing, all connections for the wings, USB, Mains and DMX are internal to hook the wings to the system.',
            'Weight without wings: 55 kg.',
        ],
        mainImage: "images/ma2fw/ma2fw_04@850w.jpg",
        images: [
            "images/ma2fw/ma2fw_03@850w.jpg",
            "images/ma2fw/ma2fw_01@850w.jpg",            
            "images/ma2fw/ma2fw_05@850w.jpg",
            "images/ma2fw/ma2fw_06@850w.jpg",
            "images/ma2fw/ma2fw_07@850w.jpg",
            "images/ma2fw/ma2fw_08@850w.jpg",
            "images/ma2fw/ma2fw_09@850w.jpg",
            "images/ma2fw/ma2fw_10@850w.jpg",
            "images/ma2fw/ma2fw_11@850w.jpg",
            "images/ma2fw/ma2fw_12@850w.jpg",
            "images/ma2fw/ma2fw_13@850w.jpg",
            "images/ma2fw/ma2fw_14@850w.jpg",
            "images/ma2fw/ma2fw_02@850w.jpg",
            "images/ma2fw/ma2fw_15@850w.jpg",
            "images/ma2fw/ma2fw_16@850w.jpg",
            "images/ma2fw/ma2fw_17@850w.jpg",
        ],
    },
    {
        name: "vistaex",
        heading: "Vista EX by Chroma-Q",
        excerpt: "High quality road case c/w wheel-board, cable compartment, 1 drawer, a shelf for mouse attachable to the side of the case with screws, foam with holes for beverages.",
        specs: [
            'External dimensions: 77cm x 62cm x 30cm.',
            'PC: Intel NUC i5, 128GB SSD, 8GB RAM, Windows 10 Pro',
            'SCREEN: 24” DELL multi-touch with anti-glare surface. The screen folds flat for transport and has 3 different viewing angles.',
            'Wireless keyboard with touchpad.',
            'UPS system.',
            'LED lighting over the drawer.',
            '1 x LED gooseneck lamp.',
            'BACK PANEL: Connections for Powercon mains inlet, 2 x DMX out, Ethernet RJ45, HDMI and USB. Back panel is protected for transport.',
            'USB 3.0 connector in front next to the drawer.',
            'Mains cable powercon-schuko 3m.',
            'The system ships assembled and configured to work.',
            'The complete system weighs 48 kg (With Vista EX).',
        ],
        mainImage: "/images/vistaex/vistaex_08@850w.jpg",
        images: [
            "/images/vistaex/vistaex_05@850w.jpg",
            "/images/vistaex/vistaex_01@850w.jpg",
            "/images/vistaex/vistaex_02@850w.jpg",
            "/images/vistaex/vistaex_03@850w.jpg",
            "/images/vistaex/vistaex_04@850w.jpg",
            "/images/vistaex/vistaex_06@850w.jpg",
            "/images/vistaex/vistaex_07@850w.jpg",
            "/images/vistaex/vistaex_09@850w.jpg",
            "/images/vistaex/vistaex_10@850w.jpg",
            "/images/vistaex/vistaex_11@850w.jpg",
            "/images/vistaex/vistaex_12@850w.jpg",
            "/images/vistaex/vistaex_13@850w.jpg",
            "/images/vistaex/vistaex_14@850w.jpg",
            "/images/vistaex/vistaex_15@850w.jpg",
            "/images/vistaex/vistaex_16@850w.jpg",
            "/images/vistaex/vistaex_17@850w.jpg",
            "/images/vistaex/vistaex_18@850w.jpg",
            "/images/vistaex/vistaex_19@850w.jpg",
        ],
    },
];

export const soundConsoles = [
    {
        name: "dlive",
        heading: "dLive Director",
        excerpt: "High quality road case c/w wheel-board, cable compartment, 1 drawer, a shelf for trackball attachable to the side of the case with screws. The system ships ready to work with all the apps installed and configured to work. It connects to the MixRack via M-Dante card with one ethernet cable. It operates Dante and Director simultaneously.",
        specs: [
            'External dimensions: 77cm x 62cm x 30cm.',
            'PC: Intel NUC i5, 128GB SSD, 8GB RAM, Windows 10 Pro.',
            'SCREENS: 2 x 24” DELL multi-touch with anti-glare surface. The top screen folds flat for transport and has 3 different viewing angles.',
            'USB Hub.',
            '5 port gigabit ethernet switch with 4 PoE ports.',
            'USB audio interface Focusrite Scarlett 2i2 to provide talkback input and headphones out. Signal is crossed to/from the interface with Dante Via with a 10 ms latency which is negligible when using it at FOH distance. At MON you can use the MixRack headphone output. We activate the 30-day trial license for Dante Via for test purposes. Permanent license (costing around 50$) is not included and should be bought via audinate website.',
            'Wireless Logitech trackball.',
            'Wireless keyboard.',
            'LED lighting over the drawer.',
            '2 x USB LED gooseneck lamp.',
            'BACK PANEL: Powercon mains inlet, 4x Ethernet (RJ45). Back panel lid is closed for transport.',
            'Mains cable powercon-schuko 3m.',
            'The system ships ready to work with all the apps installed and configured to work. It connects to the MixRack via M-Dante card with one ethernet cable. It operates Dante and Director simultaneously.',
            'The complete system weighs 43kg.',
        ],
        mainImage: "images/dlive/dlive_05@850w.jpg",
        images: [
            "images/dlive/dlive_01@850w.jpg",
            "images/dlive/dlive_02@850w.jpg",
            "images/dlive/dlive_03@850w.jpg",
            "images/dlive/dlive_04@850w.jpg",
            "images/dlive/dlive_06@850w.jpg",
            "images/dlive/dlive_07@850w.jpg",
            "images/dlive/dlive_08@850w.jpg",
            "images/dlive/dlive_09@850w.jpg",
            "images/dlive/dlive_10@850w.jpg",
            "images/dlive/dlive_11@850w.jpg",
            "images/dlive/dlive_12@850w.jpg",
            "images/dlive/dlive_13@850w.jpg",
        ],
    },
    {
        name: "waveslv1",
        heading: "Waves LV1",
        excerpt: "High quality road case c/w wheel-board, cable compartment, 1 drawer, a shelf for mouse attachable to the side of the case with screws, foam with holes for beverages. The system ships assembled and configured to work.",
        specs: [
            'External dimensions: 77cm x 62cm x 30cm.',
            'PC: Intel NUC i5, 128GB SSD, 8GB RAM, Windows 10 Pro.',
            'SCREENS: 2 x 24” DELL multi-touch with anti-glare surface. The top screen folds flat for transport and has 3 different viewing angles.',
            'USB Hub with power supply.',
            'Netgear GS108 v4 8 port switch, dedicated to operate with LV1.',
            'Wireless keyboard with touchpad.',
            'RGB LED lighting over the drawer with remote to change colours.',
            '2 x USB LED gooseneck lamp.',
            'BACK PANEL: Powercon mains inlet, 7x Ethernet CAT6e. Back panel lid is closed for transport.',
            'USB 3.0 connector in front next to the drawer.',
            'Mains cable powercon-schuko 3m.',
            'The system ships assembled and configured to work.',
            'The complete system weighs 42 kg.',
        ],
        mainImage: "images/waveslv1/waveslv1_03@850w.jpg",
        images: [
            "images/waveslv1/waveslv1_01@850w.jpg",
            "images/waveslv1/waveslv1_02@850w.jpg",
            "images/waveslv1/waveslv1_04@850w.jpg",
            "images/waveslv1/waveslv1_05@850w.jpg",
            "images/waveslv1/waveslv1_06@850w.jpg",
            "images/waveslv1/waveslv1_07@850w.jpg",
            "images/waveslv1/waveslv1_08@850w.jpg",
            "images/waveslv1/waveslv1_09@850w.jpg",
            "images/waveslv1/waveslv1_10@850w.jpg",
            "images/waveslv1/waveslv1_11@850w.jpg",
        ],
    },
    {
        name: "waveslv1l",
        heading: "Flyable lightweight",
        excerpt: "High quality lightweight case made from composite panels, lockable draw bolt latches, removable 3-stage trolley for transporting the case, cables and keyboard compartment. The system ships assembled and configured to work.",
        specs: [
            'External dimensions: 65cm x 66,5cm x 32cm.',
            'PC: Intel NUC i5, 128GB SSD, 8GB RAM, Windows 10 Pro.',
            'SCREENS: 2 x 24” DELL P2418HT multi-touch with anti-glare surface. The top screen folds flat for transport and has 3 different viewing angles.',
            'USB 3.0 Hub with power supply.',
            'Netgear GS108 v4 8 port switch, dedicated to operate with LV1.',
            'Wireless keyboard with touchpad.',
            '2 x USB LED gooseneck lamp.',
            'BACK PANEL: Powercon mains inlet, 7x Ethernet CAT6e (Neutrik).',
            'USB 3.0 connector in front next to the drawer, two USB 3.0 on the sides (Neutrik).',
            'Mains cable powercon-schuko 3m.',
            'The system ships assembled and configured to work.',
            'Weight: 26 kg / 57,5 lbs without trolley, 28 kg / 62 lbs with trolley.',
        ],
        mainImage: "images/waveslv1l/waveslv1l_04@850w.jpg",
        images: [
            "images/waveslv1l/waveslv1l_01@850w.jpg",
            "images/waveslv1l/waveslv1l_02@850w.jpg",
            "images/waveslv1l/waveslv1l_03@850w.jpg",
            "images/waveslv1l/waveslv1l_05@850w.jpg",
            "images/waveslv1l/waveslv1l_06@850w.jpg",
            "images/waveslv1l/waveslv1l_07@850w.jpg",
            "images/waveslv1l/waveslv1l_08@850w.jpg",
            "images/waveslv1l/waveslv1l_09@850w.jpg",
            "images/waveslv1l/waveslv1l_10@850w.jpg",
            "images/waveslv1l/waveslv1l_11@850w.jpg",
            "images/waveslv1l/waveslv1l_12@850w.jpg",
            "images/waveslv1l/waveslv1l_13@850w.jpg",
            "images/waveslv1l/waveslv1l_14@850w.jpg",
            "images/waveslv1l/waveslv1l_15@850w.jpg",
            "images/waveslv1l/waveslv1l_16@850w.jpg",
            "images/waveslv1l/waveslv1l_17@850w.jpg",
            "images/waveslv1l/waveslv1l_18@850w.jpg",
        ],
    },
];
